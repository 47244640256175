import * as React from "react";
import { Link } from "gatsby";
import { TrendingUpIcon, InformationCircleIcon } from "@heroicons/react/solid";

const SideBar = () => {
  return (
    <div className="py-8 px-10 md:px-0 bg-gray-800 flex flex-col h-full md:h-screen w-full text-gray-200">
      <div className="flex flex-row md:flex-col md:space-y-2 items-center">
        <Link to="/">
          <div className="flex items-center p-2">
            <TrendingUpIcon className="h-6 w-6" />
          </div>
        </Link>
        <Link to="/about">
          <div className="flex items-center p-2">
            <InformationCircleIcon className="h-6 w-6" />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default SideBar;
