import * as React from "react";
import SideBar from "./sidebar";

const Layout = ({ children }: { children: React.ReactNode; }) => {
  return (
    <>
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-20 h-full md:h-screen md:sticky top-0"><SideBar /></div>
        <main className="w-full">{children}</main>
      </div>
      <footer></footer>
    </>
  );
};

export default Layout;
