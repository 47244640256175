import * as React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const Seo = ({ description, lang, meta, title }: any) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
     `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;

  title = title !== 'Home' ? title : defaultTitle;

  return (
    <Helmet htmlAttributes={{ lang, }}
      title={title}
      titleTemplate={defaultTitle ? title !== defaultTitle ? `%s | ${defaultTitle}` : defaultTitle : defaultTitle}
      meta={[{
        name: `description`,
        content: metaDescription,
      }, {
        property: `og:title`,
        content: title,
      }, {
        property: `og:description`,
        content: metaDescription,
      }, {
        property: `og:type`,
        content: `website`,
      }, {
        name: `twitter:card`,
        content: `summary`,
      }, {
        name: `twitter:creator`,
        content: site.siteMetadata?.social?.twitter || ``,
      }, {
        name: `twitter:title`,
        content: title,
      }, {
        name: `twitter:description`,
        content: metaDescription,
      },
      ].concat(meta)}
    >
      <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
    </Helmet>
  );
};

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default Seo;
